import { Library } from '@fortawesome/fontawesome-svg-core'
import VueConstructor from 'vue'
import VueRouter from 'vue-router'
import Vuetify from 'vuetify/lib'
import { Store } from 'vuex'

import * as accuracyModule from './accuracy'
import * as alertModule from './alert'
import * as catalogModule from './catalog'
import * as codeModule from './code'
import * as commentModule from './comment'
import * as diagramModule from './diagram'
import * as domainModule from './domain'
import * as editorModule from './editor'
import * as featuresModule from './features'
import * as flowModule from './flow'
import * as historyModule from './history'
import * as interstitialModule from './interstitial'
import * as landscapeModule from './landscape'
import * as modelModule from './model'
import * as navigationModule from './navigation'
import * as organizationModule from './organization'
import * as overviewModule from './overview'
import * as recommendationModule from './recommendation'
import * as renderModule from './render'
import * as routeModule from './route'
import * as shareModule from './share'
import * as socketModule from './socket'
import * as supportModule from './support'
import * as tagModule from './tag'
import * as teamModule from './team'
import * as userModule from './user'
import * as versionModule from './version'

export const install = (options: { Vue: typeof VueConstructor, vuetify: Vuetify, store: Store<any>, router: VueRouter, library: Library }) => {
  accuracyModule.install(options)
  alertModule.install(options)
  catalogModule.install(options)
  codeModule.install(options)
  commentModule.install(options)
  diagramModule.install(options)
  domainModule.install(options)
  editorModule.install(options)
  featuresModule.install(options)
  flowModule.install(options)
  historyModule.install(options)
  interstitialModule.install(options)
  landscapeModule.install(options)
  modelModule.install(options)
  navigationModule.install(options)
  organizationModule.install(options)
  overviewModule.install(options)
  recommendationModule.install(options)
  renderModule.install(options)
  routeModule.install(options)
  shareModule.install(options)
  socketModule.install(options)
  supportModule.install(options)
  tagModule.install(options)
  teamModule.install(options)
  userModule.install(options)
  versionModule.install(options)
}

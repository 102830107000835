
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  name: 'ConnectionIssueInterstitial'
})
export default class extends Vue {
  @Prop({ default: null }) readonly message!: string | null

  reloadPage () {
    window.location.reload()
  }
}

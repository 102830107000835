import VueConstructor from 'vue'
import { Store } from 'vuex'

import ConnectionIssueInterstitial from './components/connection-issue-interstitial.vue'
import { InterstitialModule } from './store'

export const install = ({ store, Vue }: { store: Store<any>, Vue: typeof VueConstructor }) => {
  Vue.component('ConnectionIssueInterstitial', ConnectionIssueInterstitial)

  store.registerModule(InterstitialModule.namespace, InterstitialModule)
}

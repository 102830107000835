import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

export enum InterstitialType {
  connectionIssue = 'connectionIssue'
}

export interface IInterstitialModule {
  presentedType: InterstitialType | null
  message: string | null
}

const name = 'interstitial'

@Module({
  name,
  namespaced: true
})
export class InterstitialModule extends VuexModule {
  static namespace = name

  presentedType: InterstitialType | null = null
  message: string | null = null

  @Mutation
  resetInterstitial () {
    this.presentedType = null
    this.message = null
  }

  @Mutation
  pushInterstitial (params: IInterstitialModule) {
    this.presentedType = params.presentedType
    this.message = params.message
  }
}
